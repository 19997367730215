/* eslint-disable react/prop-types */
import React from 'react';
import Footer from './Footer';
import Header from './Header';
import Nav from './Nav';
import TopNav from './TopNav';
import config from '../../../config';

const pic = require('../../images/yusuf-icon.png');

export default function SideBar({ sections = [], close, toggleHeader, setClose, headerOpen }) {
  const handleToggle = () => {
    toggleHeader(!headerOpen);
    setClose(true);
  };
  return (
    <div className={`${headerOpen && close ? 'header-visible' : ' '}`}>
      <TopNav
        headerOpen={headerOpen}
        close={close}
        title={config.authorName}
        onMenuClick={handleToggle}
      />
      <div id="header">
        <div className="top">
          <Header avatar={pic} title={config.authorName} heading={config.heading} />
          <Nav sections={sections} />
        </div>
        <Footer socialLinks={config.socialLinks} />
      </div>

      {/* <section id="header">
        <Header
          avatar={pic}
          title={config.authorName}
          heading={config.heading}
        />
        <Nav sections={sections} />
        <Footer socialLinks={config.socialLinks} />
      </section> */}
    </div>
  );
}
