/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { navigate } from 'gatsby-link';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import PageFooter from '../components/PageFooter';
import SideBar from '../components/Sidebar';

import { socialLinks, skills, projects } from '../../config';

import pic2 from '../assets/images/pic02.png';
import pic3 from '../assets/images/pic03.png';
import pic4 from '../assets/images/pic04.png';
import pic6 from '../assets/images/pic06.png';

const projectImages = [pic6, pic4, pic3, pic2];

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const sections = [
  { id: 'top', name: 'Intro', icon: 'fa-home' },
  { id: 'about', name: 'About Me', icon: 'fa-user' },
  { id: 'skills', name: 'Skills', icon: 'fa-laptop' },
  { id: 'portfolio', name: 'Portfolio', icon: 'fa-th' },
  { id: 'contact', name: 'Contact', icon: 'fa-envelope' },
];

const IndexPage = () => {
  const [close, setClose] = useState(true);
  const [headerOpen, toggleHeader] = useState(false);
  const handleClose = () => {
    if (headerOpen) {
      setClose(false);
      toggleHeader(false);
    } else setClose(true);
  };
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "yusuf-avatar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  return (
    <Layout>
      <SideBar
        headerOpen={headerOpen}
        close={close}
        sections={sections}
        toggleHeader={toggleHeader}
        setClose={setClose}
      />

      <div onClick={handleClose} id="main">
        <section id="top" className="one dark cover">
          <div className="container">
            <header>
              <h2 className="alt">
                Hello! I&apos;m 
                {' '}
                <strong>Yusuf Abdulkarim</strong>
              </h2>
              <h6>A Full-Stack Web Developer</h6>
              <h6>Passionate about building for the next billion users</h6>
            </header>
            <footer>
              <ul className="icons">
                {socialLinks.map(social => {
                  const { icon, name, url } = social;
                  return (
                    <li key={url}>
                      <a href={url} className={`button icon ${icon}`}>
                        <span className="label">{name}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </footer>
          </div>
        </section>
        <section id="about" className="three">
          <div className="container">
            <header>
              <h2>About me</h2>
            </header>
            {' '}
            <Img className="image profile" fluid={data.placeholderImage.childImageSharp.fluid} />
            <p>
              It is a pleasure to have you in my cyberspace. I&apos;m a full-stack developer with a
              growing affinity for Javasript related technologies. I am proficient in Javascript, React,
              Node, Express, and Python.
            </p>
            <p>
              After almost a decade spent working in teams, researching, overcoming challenges, and
              architecting scalable systems in college and the tech community, I&apos;ve developed
              strong creative problem-solving, communication, and organizational skills. I am
              interested in applying these skills, in combination with modern web technologies, to
              build engaging and cutting-edge products.
            </p>
            <p>
              When not working on a project or thinking about programming, I&apos;m most likely out,
              sight-seeing, watching TV series on Netflix, spending time with family and friends, or
              thinking about how to contribute to poverty alleviation through technology and
              empowerment in Africa.
            </p>
          </div>
        </section>
        <section id="skills" className="four">
          <div className="container">
            <header>
              <h2>My Skills</h2>
            </header>
            <ul className="icons">
              <h3>Languages</h3>
              {skills.languages.map(skill => {
                const { name } = skill;
                return (
                  <li key={name}>
                    <button type="button" className="button">
                      <span className="label">{name}</span>
                    </button>
                  </li>
                );
              })}
              <h3>Libraries</h3>
              {skills.libraries.map(skill => {
                const { name } = skill;
                return (
                  <li key={name}>
                    <button type="button" className="button">
                      <span className="label">{name}</span>
                    </button>
                  </li>
                );
              })}
              <h3>Concepts</h3>
              {skills.concepts.map(skill => {
                const { name } = skill;
                return (
                  <li key={name}>
                    <button type="button" className="button">
                      <span className="label">{name}</span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        <section id="portfolio" className="two">
          <div className="container">
            <header>
              <h2>Portfolio</h2>
            </header>

            <p>
              Description of some of my previous projects and a link to the deployed site and github
              page
            </p>

            <div style={{ justifyContent: 'center' }} className="row">
              {projects.map((project, index) => (
                <article className="col-4 col-12-mobile item">
                  <a href={project.url} target="blank" className="image fit">
                    <img src={projectImages[index]} alt="" />
                  </a>
                  <header>
                    <h3>
                      <a href={project.url} target="blank">
                        {project.name}
                        {' '}
&nbsp;
                        <i className="icon fa-external-link" />
                      </a>
                      <a href={project.github_url} className="icon fa-github">
                        <span className="label">Github</span>
                      </a>
                    </h3>
                  </header>
                  <p>
                    {project.description}
                    <br />
                    <hr />
                    <em>{project.built_using}</em>
                  </p>
                </article>
              ))}
            </div>
          </div>
        </section>

        <section id="contact" className="four">
          <div className="container">
            <header>
              <h2>Contact</h2>
            </header>

            <p>
              Dont be a stranger
              <span role="img" aria-label="wink">
                &nbsp;😉&nbsp;
              </span>
              Send me a message and I&apos;ll reply as soon as I can
            </p>

            <form
              name="contact"
              method="post"
              action="/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" onChange={handleChange} />
              <p hidden>
                <label>
                  Don’t fill this out: 
                  {' '}
                  <input name="bot-field" />
                </label>
              </p>
              <div className="row">
                <div className="col-6 col-12-mobile">
                  <input
                    onChange={handleChange}
                    type="text"
                    required
                    name="name"
                    placeholder="Name"
                  />
                </div>
                <div className="col-6 col-12-mobile">
                  <input
                    onChange={handleChange}
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                  />
                </div>
                <div className="col-12">
                  <textarea onChange={handleChange} required name="message" placeholder="Message" />
                </div>
                <div className="col-12">
                  <input type="submit" value="Send Message" />
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>

      <PageFooter />
    </Layout>
  );
};

export default IndexPage;
