module.exports = {
  siteTitle: 'Yusuf Ayo Abdulkarim', // <title>
  manifestName: `Yusuf Ayo Abdulkarim`,
  manifestShortName: 'Yusuf Ayo', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#374458',
  manifestThemeColor: '#374458',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/images/yusuf-icon.png',
  // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Yusuf Abdulkarim',
  heading: 'Full - Stack Web Developer',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/haywhyze',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/haywhyze',
    },
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://linkedin.com/in/yusufayo',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:yusufayoabdulkarim@gmail.com',
    },
  ],
  skills: {
    languages: [
      {
        name: 'JavaScript',
      },
      {
        name: 'Node.js',
      },
      {
        name: 'HTML',
      },
      {
        name: 'CSS',
      },
      {
        name: 'SQL',
      },
      {
        name: 'Python',
      },
      {
        name: 'TypeScript',
      },
    ],
    libraries: [
      {
        name: 'React',
      },
      {
        name: 'Express',
      },
      {
        name: 'Redux',
      },
      {
        name: 'Sass/Less',
      },
    ],
    concepts: [
      {
        name: 'REST',
      },
      {
        name: 'GraphQL',
      },
      {
        name: 'Algorithms',
      },
      {
        name: 'Data Structures',
      },
      {
        name: 'Git',
      },
      {
        name: 'Agile Methodology',
      },
    ],
  },
  projects: [
    {
      name: 'BeFit',
      url: 'https://theworkouttracker.netlify.app/',
      github_url: 'https://github.com/labseu2-workout-tracker',
      description: 'A full-stack production-ready web application that helps users track workouts, follow instructional videos, and create custom workouts from over 850 exercises',
      built_using: 'Built using Javascript, React, Redux, Netlify, Git, Ant-Design System, Jest, TravisCI, Postgres (Knex) and Node',
    },
    {
      name: 'Politico',
      url: 'https://politico-yusuf.herokuapp.com/',
      github_url: 'https://github.com/haywhyze/politico',
      description:
        'Politico enables citizens give their mandate to politicians running for different government offices while building trust in the process through transparency.',
      built_using: 'Built using vanilla  Javascript, CSS (No frameworks), Express, PostgreSQL, Mocha, TravisCI, Git, Cloudinary, ES6, Babel, ESLint, Heroku, NodeJS.',
    },
    {
      name: 'LifeGPA',
      url: 'https://lifegpa-abdulkarim.netlify.com/',
      github_url: 'https://github.com/lambdaschool-lifegpa/lifeGPA-UI-Abdulkarim',
      description: 'LifeGPA helps track your habits across all aspect of life and give a simple composite metric. I worked on the marketing page only',
      built_using: 'Built using HTML, CSS, Javascript, GreenSock',
    },
    {
      name: `I'll Serve Soup`,
      url: 'https://webeu-serve-soup.netlify.com/',
      github_url: 'https://github.com/i-ll-serve-soup-bweu/Frontend',
      description: 'Teamed up with two other developers across two continents and three timezones to assemble a simple soup kitchen management software that allows inventory tracking and easy user sign up in 4 days.',
      built_using: 'Built using Javascript React, Redux, styled-component',
    },
  ],
};
