/* eslint-disable react/prop-types */
import React from 'react';

export default function TopNav({ headerOpen, close, onMenuClick = () => {} }) {
  return (
    <div id="headerToggle">
      <a
        href="/#"
        className={`${headerOpen && close ? 'toggle toggle-close' : 'toggle'}`}
        onClick={e => {
          e.preventDefault();
          onMenuClick();
        }}
      >
        {' '}
      </a>
    </div>
  );
}
